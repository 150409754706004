import { makeStyles } from '@material-ui/core/styles';
import * as COLORS from '../../../../constants/colors';

export const chartOptions = {
    legend: 'none',
    colors: [COLORS.YELLOW],
    backgroundColor: { fill: 'transparent' },
    chartArea: { width: '80%', height: '80%' },
    vAxis: { minValue: 0 },
    hAxis: {
        minValue: 0,
        textStyle: {
            fontSize: 9,
            fontName: 'Arial',
        },
    },
    annotations: {
        textStyle: {
            fontSize: 12,
            fontName: 'Arial',
        },
    },
    bar: {
        groupWidth: '70%',
    },
    tooltip: {
        trigger: 'none',
    },

};

export const useStyles = makeStyles(theme => ({

    grid: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

}));
