import React, { Component } from 'react';
import Chart from "react-google-charts";
import { Grid } from '@material-ui/core';
import { dateToString } from '../../utils';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import FloatingCard from '../../utils/GenericComponents/floatingCard';

import { useStyles, chartOptions } from './styles';

const MRRRecap = (props) => {
    const classes = useStyles();
    return <MRRRecapComponent {...props} classes={classes} />;
};

const DisplayChart = props => {
    return (
        <Chart
            data={props.mrr}
            options={chartOptions}
            chartType="ColumnChart"
            rootProps={{ 'data-testid': '1' }}
            loader={<div>Loading Chart...</div>}
        />
    )
}

class MRRRecapComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paid: null,
            fifo: null,
            transit: null,
            loading: false,
        };
        this.classes = props.classes;
        this.authUser = props.authUser;
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.props.firebase.db.ref('business/saas').on('value', snapshot => {
            this.setState({
                loading: false,
                fifo: snapshot.val()?.fifo,
                paid: snapshot.val()?.paid,
                transit: snapshot.val()?.transit,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.db.ref('business/saas').off();
    }

    render() {
        const { paid, fifo, transit } = this.state;

        const totalFifo = Object.values(fifo || {}).reduce((total, payment) => total + parseFloat(payment.net), 0);
        const totalTransit = Object.values(transit || {}).reduce((total, payment) => total + parseFloat(payment.net), 0);

        const mrr = [['Month', 'Amount', { role: 'annotation' }]];
        if (paid) {
            // I want to display the last 12 months
            // I want the amount to be the sum of the payments for each month at the end of the month
            const lastYear = Date.now() - (1000 * 60 * 60 * 24 * 365);
            const months = {};

            Object.values(paid).forEach(payment => {
                if (new Date(payment.created_at).getTime() >= lastYear) {
                    const month = new Date(payment.created_at).getFullYear() + "-" + (new Date(payment.created_at).getMonth() + 1);
                    if (!months[month]) {
                        months[month] = 0;
                    }
                    months[month] += parseFloat(payment.net);
                }
            });

            Object.keys(months).forEach((month, count) => {
                if (count === 12) {
                    const value = months[month] + totalFifo + totalTransit;
                    mrr.push([month, value, value.toFixed(0)]);
                } else mrr.push([month, months[month], months[month].toFixed(0)]);
            });

        }

        return (
            <FloatingCard
                noflex={true}
                avatar={<DescriptionIcon />}
                title={`MRR`}
            >
                <Grid container spacing={2} justifyContent="center" alignItems="flex-end" className={this.classes.grid}>
                    <Grid item xs={12} sm={12} className={this.classes.gridItemFifo}>
                        <DisplayChart mrr={mrr} />
                    </Grid>
                </Grid>
            </FloatingCard>
        );
    }
}

export default MRRRecap;