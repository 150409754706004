import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import PaymentsRecapPanel from './paymentRecapPanel';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import CreateNewPayoutInTransit from './createNewPayoutInTransit';
import FloatingCard from '../../utils/GenericComponents/floatingCard';

import { useStyles } from './styles';

const Payout = (props) => {
    const classes = useStyles();
    return <PayoutComponent {...props} classes={classes} />;
};

class PayoutComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paid: null,
            fifo: null,
            transit: null,
            loading: false,
        };
        this.classes = props.classes;
        this.authUser = props.authUser;
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.props.firebase.db.ref('business/saas').on('value', snapshot => {
            this.setState({
                loading: false,
                fifo: snapshot.val()?.fifo,
                paid: snapshot.val()?.paid,
                transit: snapshot.val()?.transit,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.db.ref('business/saas').off();
    }

    onCreateNewPayoutInTransit = (payout) => {
        const key = Date.now();
        this.props.firebase.db.ref('business/saas/transit').update({ [key]: payout });
    }

    getLastMonthMRR = () => {
        // taking in account that if we are in January, we need to get the MRR of December of the previous year
        const month = new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1;
        const year = new Date().getMonth() === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear();
        return Object.values(this.state.paid || {}).filter(payment => new Date(payment.created_at).getMonth() === month && new Date(payment.created_at).getFullYear() === year).reduce((total, payment) => total + parseFloat(payment.net), 0);
    }

    render() {
        const { paid, fifo, transit } = this.state;
        const mrrLastMonth = this.getLastMonthMRR();
        const mrr = Object.values(paid || {}).filter(payment => new Date(payment.created_at).getMonth() === new Date().getMonth() && new Date(payment.created_at).getFullYear() === new Date().getFullYear()).reduce((total, payment) => total + parseFloat(payment.net), 0);
        const totalFifo = Object.values(fifo || {}).reduce((total, payment) => total + parseFloat(payment.net), 0);
        const totalTransit = Object.values(transit || {}).reduce((total, payment) => total + parseFloat(payment.net), 0);

        return (
            <FloatingCard
                noflex={true}
                avatar={<DescriptionIcon />}
                title={`Payouts - This month: ${(mrr + totalFifo + totalTransit).toFixed(2)}€ (Last month: ${(mrrLastMonth).toFixed(2)}€)`}
            >
                <Grid container spacing={3} justifyContent="center" alignItems="flex-end" className={this.classes.grid}>
                    <Grid item xs={12} sm={6} className={this.classes.gridItemFifo}>
                        <PaymentsRecapPanel
                            list={fifo}
                            type='fifo'
                            amount={totalFifo.toFixed(2)}
                        />
                        <Typography variant="caption" color='textSecondary' align='center' >Payments succeeded in Stripe</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className={this.classes.gridItemTransit}>
                        <PaymentsRecapPanel
                            list={transit}
                            type='transit'
                            amount={totalTransit.toFixed(2)}
                        />
                        <Typography variant="caption" color='textSecondary' align='center' >Payments sent out from Stripe</Typography>
                    </Grid>
                </Grid>
                <CreateNewPayoutInTransit onCreateNewPayoutInTransit={this.onCreateNewPayoutInTransit} />
            </FloatingCard>
        );
    }
}

export default Payout;