import { makeStyles } from '@material-ui/core/styles';
import { YELLOW } from '../../../../../constants/colors';

export const chartOptions = {
    legend: 'none',
    colors: [YELLOW],
    backgroundColor: { fill: 'transparent' },
    chartArea: { width: '80%' },
    vAxis: { minValue: 0 },
    hAxis: {
        minValue: 0,
        textStyle: {
            fontSize: 9,
            fontName: 'Arial',
        },            
    },
    annotations: {
        textStyle: {
            fontSize: 9,
            fontName: 'Arial',
        },
    },
    bar: {
        groupWidth: '70%',
    },
    tooltip: {
        //trigger: 'none',
    },

};

export const useStyles = makeStyles((theme) => ({

    grid: {
        marginTop: -theme.spacing(5),
    },

    message: {
        border: '1px solid black',
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },

}));
